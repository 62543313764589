<template>
<div :class="$style.root">
  <VForm ref="form" v-model="valid" @submit.prevent="onSubmit" @reset.prevent="onReset">
    <VRow>
      <VCol cols="12">
        <div v-if="name" class="mb-2"><b>{{name}}</b></div>
        <FileFieldMultiple v-model="fields['files']" :filled="fields['files'].length > 0" :multiple="false" required />
        <div v-if="description" class="mt-1">{{ description }}</div>
      </VCol>
      <VCol cols="12" md="3">
        <VTextField
            dense
            outlined
            hide-details="auto"
            append-icon="mdi-calendar"
            label="Дата загрузки"
            disabled
            :value="date"
        />
      </VCol>
      <VCol cols="12" md="9">
        <VRow>
          <VCol cols="12" md="6">
            <DateInput
                label="Дата начала срока действия"
                type="DATE"
                required
                :value="fields['date']"
                :rules="[!!fields['date'] || 'Обязательное поле']"
                @input="value => fields['date'] = value"
            />
          </VCol>
          <VCol cols="12" md="6">
            <DateInput
                label="Дата окончания срока действия"
                :value="fields['end']"
                @input="value => fields['end'] = value"
            />
          </VCol>
        </VRow>
      </VCol>
      <VCol cols="12">
        <VBtn depressed color="primary" type="submit" :disabled="!valid || !fields['files'].length > 0">Загрузить</VBtn>
      </VCol>
    </VRow>
  </VForm>
</div>
</template>

<script>
import { map, head, split, reverse, join } from 'lodash-es';
import { mapActions } from 'vuex';
import DateInput from '@/components/general/DateInput/DateInput';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
export default {
  name: 'FileEdit',
  components: {
    DateInput,
    FileFieldMultiple,
  },
  props: {
    id: { type: String },
    name: { type: String },
    size: { type: Number },
    format: { type: String },
    files: { type: Array },
  },
  data() {
    return {
      valid: false,
      fields: { end: '', date: '', files: [] },
    }
  },
  computed: {
    description() {
      const result = [];
      if (this.format) result.push(`Доступные форматы: ${this.format}.`);
      if (this.size) result.push(`Размер не более ${this.size}МБ.`);
      return result.join(' ');
    },
    date() {
      return join(reverse(split(new Date().toISOString().slice(0, 10), '-')), '.')
    }
  },
  methods: {
    ...mapActions({
      create: 'docs/createDocument',
    }),
    onReset: function() {
      this.fields = { end: '', date: '', files: [] };
    },
    onSubmit: function () {
      this.create({
        id: head(map(this.fields.files, 'value')),
        idParent: head(map(this.files, 'id')),
        idTypeDoc: this.id,
        dateFrom: new Date(join(reverse(split(this.fields.date, '.')), '-')).getTime(),
        dateTo: new Date(join(reverse(split(this.fields.end, '.')), '-')).getTime(),
      }).then((result) => {
        if (result) {
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Документ загружен',
          });
          this.$emit('submit', { id: this.id });
        }
      });
    },
  },
  mounted() {
    this.fields = { end: '', date: '', files: [] };
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
