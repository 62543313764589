<template>
  <div :class="$style.root">
    <VAlert type="warning" outlined dense :icon="false">
      <div class="body-2">
        Документы размещенные в данном разделе могут быть использованы на этапе заключения договора с поставщиком группы компаний РУК и не обязательны для заполнения на этапе участия в конкурентных процедурах
      </div>
    </VAlert>
    <VAlert v-if="progress < 100" :type="progressColor" dense :icon="false">
      <div class="body-2">
        Документы заполнены на {{ progress }}%. Возможен риск несогласования договора!
      </div>
    </VAlert>
    <div>
      <FileHistory v-for="item in documents" v-bind="item" :key="item.id" @edit="() => onEdit(item)" />
    </div>
    <VDialog v-if="edit" :value="!!edit" @click:outside="edit = null" persistent max-width="810">
      <VCard tile elevation="0">
        <VToolbar flat dark color="primary" class="mb-3">
          <VCardTitle v-if="edit">
            {{ edit.id ? 'Загрузить новый документ': 'Загрузить документ'}}
          </VCardTitle>
          <VSpacer/>
          <VBtn icon dark @click="edit = null">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText>
          <FileEdit v-bind="{...edit}" @submit="onSubmit" />
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import { round, get, sortBy, values, reduce, keys } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import FileHistory from '@/components/docs/FileHistory/FileHistory';
import FileEdit from '@/components/docs/FileEdit/FileEdit';
export default {
  name: 'DocsDetail',
  components: {
    FileHistory,
    FileEdit,
  },
  props: {
    id: { type: String },
    items: { type: Object },
  },
  data() {
    return {
      edit: null,
    };
  },
  computed: {
    ...mapGetters({
      pending: 'docs/pending',
      user: 'user/current',
    }),
    documents() {
      return sortBy(values(this.items), 'order');
    },
    progress() {
      const total = keys(this.items).length || 0;
      const field = reduce(this.items, (acc, item) => {
        if (get(item, 'files.0.actual', false)) acc += 1;
        return acc;
      }, 0);
      return total ? round((field * 100) / total) : 0;
    },
    progressColor() {
      if (this.progress >= 80) return 'success';
      if (this.progress >= 60) return 'warning';
      return 'error';
    }
  },
  methods: {
    ...mapActions({
      fetchDocumentDetail: 'docs/fetchDocumentDetail',
    }),
    onEdit: function(item) {
      this.edit = item;
    },
    onSubmit() {
      this.edit = null;
      const id = get(this.user, 'id');
      if (id) this.fetchDocumentDetail({ id });
    }
  }
}
</script>

<style module lang="scss">
.root {}
</style>
