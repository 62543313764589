<template>
  <div :class="$style.root">
    <div class="mb-4">
      <h1 v-if="$route.meta.title" class="mb-0">{{$route.meta.title}}</h1>
    </div>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({ name: 'user/ProfileView' })">
        <VIcon>mdi-arrow-left</VIcon> Назад
      </VBtn>
    </div>
    <div :class="$style.inner">
      <template v-if="hasAccess">
        <DocsDetail v-if="current" v-bind="current" />
      </template>
      <template v-else>
        <VAlert type="error" outlined dense prominent>
          <div class="body-2" v-html="roleMessage" />
        </VAlert>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce, get } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import DocsDetail from '@/components/docs/DocsDetail/DocsDetail';
import {ROLES} from '@/store/user/enums';

export default {
  name: 'DocsDetailView',
  components: {
    DocsDetail,
  },
  computed: {
    ...mapGetters({
      pending: 'docs/pending',
      current: 'docs/document',
      user: 'user/current',
      hasRole: 'user/hasRole',
    }),
    hasAccess() {
      return this.hasRole(ROLES.USER, 'docs');
    },
    roleMessage() {
      return get(this.user, 'role.docs.message', '');
    },
  },
  methods: {
    ...mapActions({
      fetchDocumentDetail: 'docs/fetchDocumentDetail',
    }),
    onUpdateState: debounce(function() {
      const id = get(this.user, 'id');
      if (this.hasAccess) this.fetchDocumentDetail({ id });
    }, 500),
  },
  watch: {
    id: {
      immediate: true,
      handler: function() {
        this.onUpdateState();
      }
    },
  },
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .inner {
    position: relative;
  }
}
</style>
